import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/layout/SEO'
import Layout from '../components/layout/LayoutInner'
import InnerHead from '../components/layout/InnerHead'

const testimonials = ({ data }) => {
  return (
    <Layout>
      <Seo
        title='Our Client Testimonials'
        canonical='testimonials/'
      />
      <InnerHead title="Hear it From Our Clients" caption="Testimonials" />
      <main className="testimonial-page">
        <div className="container">
          <ul>
            {data.allTestimonialsJson.edges.map(({ node }) => {
              return (
                <li key={node.id}>
                  <blockquote>
                    <p><strong>
                      {node.headline}
                    </strong></p>
                    <p className="reviewText">
                      {node.review}
                    </p>
                    <div className="quote">&ldquo;</div>
                    <p>
                      {node.author}
                    </p>
                  </blockquote>
                </li>
              )
            })}
          </ul>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allTestimonialsJson {
      edges {
        node {
          id
          author
          headline
          review
        }
      }
    }
  }
`

export default testimonials;